var allStates = $("svg#svg-content > *");

allStates.on("click", function() {
  if ($(this).hasClass("on")) {
    $(this).removeClass("on");
  } else {
    $(this).addClass("on");
  };
});

/*
$(function () {
  $('#GB-UKC').popover({
    container: 'body',
    title: 'North East England',
    content: 'Contact our local office in the North East of England:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKD').popover({
    container: 'body',
    title: 'North West England',
    content: 'Contact our local office in the North West of England:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'left',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKE').popover({
    container: 'body',
    title: 'Yorkshire and the Humber',
    content: 'Contact our local office in the Yorkshire and the Humber region:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKF').popover({
    container: 'body',
    title: 'East Midlands',
    content: 'Contact our local office in the East Midlands:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKG').popover({
    container: 'body',
    title: 'West Midlands',
    content: 'Contact our local office in the West Midlands:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'left',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKH').popover({
    container: 'body',
    title: 'East of England',
    content: 'Contact our local office in the East of England:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKI').popover({
    container: 'body',
    title: 'Greater London',
    content: 'Contact our local office in Greater London:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKJ').popover({
    container: 'body',
    title: 'South East England',
    content: 'Contact our local office in the South East of England:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKK').popover({
    container: 'body',
    title: 'South West England',
    content: 'Contact our local office in the South West of England:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'left',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKL').popover({
    container: 'body',
    title: 'Wales',
    content: 'Contact our local office in the Wales:<ul><li>Tel: XXXX-XX-XX-XX</li><li>Email: atbprojects-region@commercial-builders.co.uk</li></ul>',
    placement: 'left',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKM').popover({
    container: 'body',
    title: 'Scotland',
    content: 'More details coming soon.',
    placement: 'right',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#GB-UKN').popover({
    container: 'body',
    title: 'Northern Ireland',
    content: 'More details coming soon.',
    placement: 'top',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
  $('#IE').popover({
    container: 'body',
    title: 'Republic of Ireland',
    content: 'More details coming soon.',
    placement: 'left',
    trigger: 'hover click',
    delay: { 'show': 0, 'hide': 100 },
    html: true
  });
})

*/

/* {id:"GB-UKC"},{id:"GB-UKD"},{id:"GB-UKE"},{id:"GB-UKF"},{id:"GB-UKG"},{id:"GB-UKH"},{id:"GB-UKI"},{id:"GB-UKJ"},{id:"GB-UKK"},{id:"GB-UKL"},{id:"GB-UKM"},{id:"GB-UKN"},{id:"GG"},{id:"JE"},{id:"IM"},{id:"IE"}
*/
